export default function generateRoutes() {
    return [
        {
            path: '/:lang(fr|en|nl)',
            meta: {
                showChat: true,
            },
            children: [
                {
                    path: '',
                    name: 'home',
                    component: () => import('@/js/views/public/home/Home.vue'),
                },
                {
                    path: 'an-error-has-occurred',
                    name: 'an-error-has-occurred',
                    component: () => import('@/js/views/errors/Generic.vue'),
                },
                {
                    path: 'error-403',
                    name: '403',
                    component: () => import('@/js/views/errors/403.vue'),
                },
                {
                    path: '404',
                    name: '404',
                    component: () => import('@/js/views/errors/404.vue'),
                },
                {
                    path: 'login',
                    redirect: {
                        name: 'login',
                    },
                },
                {
                    path: 'myKameo',
                    redirect: {
                        name: 'login',
                    },
                },
                {
                    path: 'ekey-redirect',
                    name: 'ekey-redirect',
                    component: () =>
                        import('@/js/views/public/auth/EkeyRedirect.vue'),
                },
                {
                    path: 'connexion',
                    name: 'login',
                    component: () => import('@/js/views/public/auth/Login.vue'),
                },
                {
                    path: 'edgar',
                    name: 'edgar',
                    component: () => import('@/js/views/public/auth/Edgar.vue'),
                },

                // PASSWORD
                {
                    path: 'forgot-password',
                    name: 'forgot-password',
                    component: () =>
                        import('@/js/views/public/auth/Password.vue'),
                },
                {
                    path: 'new-access',
                    name: 'new-access',
                    component: () =>
                        import('@/js/views/public/auth/CreateAccess.vue'),
                },
                {
                    path: 'new-access-edgar',
                    name: 'new-access-edgar',
                    component: () =>
                        import('@/js/views/public/auth/CreateAccessEdgar.vue'),
                },

                {
                    path: 'password/reset/:token',
                    name: 'reset-password',
                    component: () =>
                        import('@/js/views/public/auth/Password.vue'),
                },
                // Monitoring
                {
                    path: 'dashboard/monitoring',
                    name: 'home-monitoring',
                    meta: {
                        permissions: ['admin'],
                    },
                    component: () => import('@/js/views/monitoring/Home.vue'),
                },
                // BLOG
                {
                    path: 'blog',
                    name: 'blog',
                    component: () => import('@/js/views/public/blog/Home.vue'),
                },
                {
                    path: 'blog/:slug/details',
                    name: 'blog-article',
                    component: () =>
                        import('@/js/views/public/blog/Details.vue'),
                },
                {
                    path: 'blog/comment-choisir-son-velo',
                    name: 'comment-choisir-son-velo',
                    component: () =>
                        import('@/js/views/public/blog/choisir_son_velo.vue'),
                },

                // SHOP
                {
                    path: 'shop',
                    name: 'home-shop',
                    component: () => import('@/js/views/public/shop/Home.vue'),
                },
                {
                    path: 'shop/catalog/bikes',
                    name: 'bikes-catalog-category',
                    component: () =>
                        import('@/js/views/public/shop/bikes/Category.vue'),
                },
                {
                    path: 'shop/catalog/bikes/occasions',
                    name: 'occasions-catalog',
                    component: () =>
                        import('@/js/views/public/shop/bikes/Occasions.vue'),
                },
                {
                    path: 'shop/catalogue-velo/detail/:id',
                    redirect: to => {
                        return {
                            name: 'catalog-bikes-details',
                            params: {
                                id: to.params.id,
                                utilisation: 'all',
                                lang: to.params.lang,
                            },
                        };
                    },
                },
                {
                    path: 'shop/catalog/:utilisation/bikes/:id/details',
                    name: 'catalog-bikes-details',
                    component: () =>
                        import('@/js/views/public/shop/bikes/Details.vue'),
                },
                {
                    path: 'shop/catalog/occasion/:id/details',
                    name: 'catalog-bikes-details-occasion',
                    component: () =>
                        import(
                            '@/js/views/public/shop/bikes/OccasionDetails.vue'
                        ),
                },
                {
                    path: 'shop/catalog/accessories/categories',
                    name: 'accessories-catalog-categories',
                    component: () =>
                        import(
                            '@/js/views/public/shop/accessories/categories.vue'
                        ),
                },

                {
                    path: 'shop/catalog/:category',
                    name: 'accessories-catalog-category',
                    component: () =>
                        import(
                            '@/js/views/public/shop/accessories/Category.vue'
                        ),
                },
                {
                    path: 'shop/catalog/:category/accessories/:id/details',
                    redirect: {
                        name: 'catalog-accessoires-details',
                    },
                },
                {
                    path: 'shop/catalog/accessories/:id/details',
                    name: 'catalog-accessoires-details',
                    component: () =>
                        import(
                            '@/js/views/public/shop/accessories/Details.vue'
                        ),
                },
                {
                    path: 'shop/upway-pricing-module',
                    name: 'upway-pricing-module',
                    component: () =>
                        import('@/js/views/public/shop/Recovery.vue'),
                },
                {
                    path: 'favoris',
                    name: 'favoris',
                    component: () =>
                        import('@/js/views/public/shop/favoris/Favoris.vue'),
                },
                {
                    path: 'cart',
                    name: 'cart',
                    component: () =>
                        import('@/js/views/public/shop/cart/Cart.vue'),
                },
                {
                    path: 'checkout',
                    name: 'cart-checkout',
                    component: () =>
                        import('@/js/views/public/shop/cart/Checkout.vue'),
                },
                // PRO
                {
                    path: 'pro',
                    name: 'home-pro',
                    component: () => import('@/js/views/public/pro/Home.vue'),
                },
                {
                    path: 'pro/employer',
                    name: 'pro-employer',
                    component: () =>
                        import('@/js/views/public/pro/Employer.vue'),
                },
                {
                    path: 'pro/employee',
                    name: 'pro-employee',
                    component: () =>
                        import('@/js/views/public/pro/Employee.vue'),
                },
                {
                    path: 'pro/independent',
                    name: 'pro-independent',
                    component: () =>
                        import('@/js/views/public/pro/Independent.vue'),
                },
                {
                    path: 'pro/shared-bikes',
                    name: 'pro_shared-bikes',
                    component: () =>
                        import('@/js/views/public/pro/SharedBikes.vue'),
                },
                {
                    path: 'pro/catalog/bikes',
                    name: 'pro-catalog-bikes',
                    component: () =>
                        import('@/js/views/public/pro/Catalog.vue'),
                },
                {
                    path: 'pro/catalog/bikes/:id/details',
                    name: 'pro-catalog-bikes-details',
                    component: () =>
                        import('@/js/views/public/pro/CatalogDetails.vue'),
                },

                {
                    path: 'pro/questionnaire-reno',
                    name: 'questionnaire-reno',
                    component: () =>
                        import('@/js/views/public/pro/questionnaires/Reno.vue'),
                },
                {
                    path: 'pro/questionnaire-ima-benelux',
                    name: 'questionnaire-ima-benelux',
                    component: () =>
                        import('@/js/views/public/pro/questionnaires/IMA.vue'),
                },
                {
                    path: 'pro/questionnaire-atradius',
                    name: 'questionnaire-atradius',
                    component: () =>
                        import(
                            '@/js/views/public/pro/questionnaires/Atradius.vue'
                        ),
                },

                // REPAIR
                {
                    path: 'repair',
                    name: 'home-repair',
                    component: () =>
                        import('@/js/views/public/repair/Home.vue'),
                },
                // CONTACT
                {
                    path: 'contact',
                    name: 'contact',
                    component: () => import('@/js/views/public/Contact.vue'),
                },
                // CONTACT
                {
                    path: 'thank-you-for-contacting-us',
                    name: 'thank-you-for-contacting-us',
                    component: () => import('@/js/views/public/Merci.vue'),
                },
                {
                    path: 'thank-you-for-ordering',
                    name: 'thank-you-for-ordering',
                    component: () => import('@/js/views/public/Merci.vue'),
                },
                {
                    path: 'thank-you-for-the-test-request',
                    name: 'thank-you-for-the-test-request',
                    component: () => import('@/js/views/public/Merci.vue'),
                },
                {
                    path: 'thank-you-for-the-leasing-request',
                    name: 'thank-you-for-the-leasing-request',
                    component: () => import('@/js/views/public/Merci.vue'),
                },
                {
                    path: 'thank-you-for-the-bike-plan-request',
                    name: 'thank-you-for-the-bike-plan-request',
                    component: () => import('@/js/views/public/Merci.vue'),
                },
                {
                    path: 'thank-you-for-the-offer-leasing-B2B-request',
                    name: 'thank-you-for-the-offer-leasing-B2B-request',
                    component: () => import('@/js/views/public/Merci.vue'),
                },
                {
                    path: 'thank-you-for-your-maintenance-request',
                    name: 'thank-you-for-your-maintenance-request',
                    component: () => import('@/js/views/public/Merci.vue'),
                },

                // LEGAL
                {
                    path: 'privacy-policy',
                    name: 'privacy-policy',
                    component: () =>
                        import('@/js/views/public/legal/PrivacyPolicy.vue'),
                },
                {
                    path: 'CGU',
                    name: 'CGU',
                    component: () => import('@/js/views/public/legal/CGU.vue'),
                },
                // DASHBOARD
                {
                    path: 'dashboard',
                    name: 'index-dashboard',
                    component: () => import('@/js/views/dashboard/Index.vue'),
                    redirect: {
                        name: 'dashboard',
                    },
                    meta: {
                        showChat: false,
                    },
                    children: [
                        // DASHBOARD - HOME
                        {
                            path: '',
                            name: 'dashboard',
                            component: () =>
                                import('@/js/views/dashboard/Home.vue'),
                        },
                        // MEP
                        {
                            path: 'mep',
                            name: 'mep',
                            meta: {
                                permissions: ['admin'],
                            },
                            component: () =>
                                import('@/js/views/dashboard/others/Mep.vue'),
                        },
                        // DASHBOARD - USERS
                        {
                            path: 'user-settings',
                            name: 'user-settings',
                            component: () =>
                                import(
                                    '@/js/views/dashboard/users/UserSettings.vue'
                                ),
                        },
                        {
                            path: 'user/create',
                            name: 'user-create',
                            meta: {
                                permissions: ['admin'],
                            },
                            component: () =>
                                import(
                                    '@/js/views/dashboard/users/UserDetails.vue'
                                ),
                        },
                        {
                            path: 'users/list',
                            name: 'users-list',
                            meta: {
                                permissions: ['admin'],
                            },
                            component: () =>
                                import(
                                    '@/js/views/dashboard/users/UsersList.vue'
                                ),
                        },

                        {
                            path: 'users/:id/details/:from?',
                            name: 'users-details',
                            meta: {
                                permissions: ['admin'],
                            },
                            component: () =>
                                import(
                                    '@/js/views/dashboard/users/UserDetails.vue'
                                ),
                        },
                        {
                            path: 'client/create/:from?',
                            name: 'client-create',
                            meta: {
                                permissions: ['admin'],
                            },
                            component: () =>
                                import(
                                    '@/js/views/dashboard/companies/CompanyDetails.vue'
                                ),
                        },
                        {
                            path: 'company/:id/process',
                            name: 'companies-processes',
                            meta: {
                                permissions: ['admin'],
                            },
                            component: () =>
                                import(
                                    '@/js/views/dashboard/companies/CompanyProcesses.vue'
                                ),
                        },

                        // DASHBOARD - COMPANIES
                        {
                            path: 'companies/list',
                            name: 'companies-list',
                            meta: {
                                permissions: ['admin'],
                            },
                            component: () =>
                                import(
                                    '@/js/views/dashboard/companies/CompanyList.vue'
                                ),
                        },
                        {
                            path: 'companies/:id/details',
                            name: 'companies-details',
                            meta: {
                                permissions: ['admin'],
                            },
                            component: () =>
                                import(
                                    '@/js/views/dashboard/companies/CompanyDetails.vue'
                                ),
                        },
                        // DASHBOARD - COMPANIES - CONTACTS
                        {
                            path: 'companies/contacts/:id/details/:from?',
                            name: 'companies-contacts-details',
                            meta: {
                                permissions: ['admin'],
                            },
                            component: () =>
                                import(
                                    '@/js/views/dashboard/companies/contacts/ContactDetails.vue'
                                ),
                        },
                        {
                            path: 'companies/contacts/:id/create',
                            name: 'companies-contacts-create',
                            meta: {
                                permissions: ['admin'],
                            },
                            component: () =>
                                import(
                                    '@/js/views/dashboard/companies/contacts/ContactDetails.vue'
                                ),
                        },
                        // DASHBOARD - COMPANIES - CONDITIONS
                        {
                            path: 'companies/conditions/:id/details',
                            name: 'companies-conditions-details',
                            meta: {
                                permissions: ['admin'],
                            },
                            component: () =>
                                import(
                                    '@/js/views/dashboard/companies/conditions/ConditionDetails.vue'
                                ),
                        },
                        {
                            path: 'companies/conditions/:id/cafeteria-details',
                            name: 'companies-conditions-cafeteria-details',
                            meta: {
                                permissions: ['admin'],
                            },
                            component: () =>
                                import(
                                    '@/js/views/dashboard/companies/conditions/cafeteria/CafeteriaDetails.vue'
                                ),
                        },
                        {
                            path: 'companies/conditions/:id/partage-list',
                            name: 'companies-conditions-partage-list',
                            meta: {
                                permissions: ['admin'],
                            },
                            component: () =>
                                import(
                                    '@/js/views/dashboard/companies/conditions/partage/PartageList.vue'
                                ),
                        },
                        {
                            path: 'companies/conditions/:id/partage-create',
                            name: 'companies-conditions-partage-create',
                            meta: {
                                permissions: ['admin'],
                            },
                            component: () =>
                                import(
                                    '@/js/views/dashboard/companies/conditions/partage/PartageDetails.vue'
                                ),
                        },
                        {
                            path: 'companies/conditions/:id/partage-details/:conditionId',
                            name: 'companies-conditions-partage-update',
                            meta: {
                                permissions: ['admin'],
                            },
                            component: () =>
                                import(
                                    '@/js/views/dashboard/companies/conditions/partage/PartageDetails.vue'
                                ),
                        },
                        {
                            path: 'companies/document/:id/create',
                            name: 'companies-document-create',
                            meta: {
                                permissions: ['admin'],
                            },
                            component: () =>
                                import(
                                    '@/js/views/dashboard/companies/documents/DocumentCreate.vue'
                                ),
                        },

                        // DASHBOARD - BIKES STOCK
                        {
                            path: 'bikes-stock/create',
                            name: 'bikes-stock-create',
                            meta: {
                                permissions: ['admin'],
                            },
                            component: () =>
                                import(
                                    '@/js/views/dashboard/bikesStock/BikesStockDetails.vue'
                                ),
                        },
                        {
                            path: 'bikes-stock/:id/details',
                            name: 'bikes-stock-details',
                            meta: {
                                permissions: ['admin'],
                            },
                            component: () =>
                                import(
                                    '@/js/views/dashboard/bikesStock/BikesStockDetails.vue'
                                ),
                        },
                        {
                            path: 'bikes-stock/list',
                            name: 'bikes-stock-list',
                            meta: {
                                permissions: ['admin'],
                            },
                            component: () =>
                                import(
                                    '@/js/views/dashboard/bikesStock/BikesList.vue'
                                ),
                        },
                        {
                            path: 'leasing-end/list',
                            name: 'leasing-end',
                            meta: {
                                permissions: ['admin'],
                            },
                            component: () =>
                                import(
                                    '@/js/views/dashboard/bikesStock/EndLeasingList.vue'
                                ),
                        },
                        {
                            path: 'leasing-end/create',
                            name: 'leasing-end-create',
                            meta: {
                                permissions: ['admin'],
                            },
                            component: () =>
                                import(
                                    '@/js/views/dashboard/bikesStock/EndLeasingCreate.vue'
                                ),
                        },

                        {
                            path: 'accessories-stock/list',
                            name: 'accessories-stock-list',
                            meta: {
                                permissions: ['admin'],
                            },
                            component: () =>
                                import(
                                    '@/js/views/dashboard/accessoriesStock/AccessoriesList.vue'
                                ),
                        },

                        // DASHBOARD - Inventaire

                        {
                            path: 'inventory',
                            name: 'inventory',
                            meta: {
                                permissions: ['admin'],
                            },
                            component: () =>
                                import(
                                    '@/js/views/dashboard/Inventory/InventoryList.vue'
                                ),
                        },
                        {
                            path: 'inventory/create',
                            name: 'inventory-create',
                            meta: {
                                permissions: ['admin'],
                            },
                            component: () =>
                                import(
                                    '@/js/views/dashboard/Inventory/InventoryDetails.vue'
                                ),
                        },

                        {
                            path: 'inventory/:id/details',
                            name: 'inventory-details',
                            meta: {
                                permissions: ['admin'],
                            },
                            component: () =>
                                import(
                                    '@/js/views/dashboard/Inventory/InventoryDetails.vue'
                                ),
                        },
                        {
                            path: 'inventory-detail/:id/details',
                            name: 'inventoryDetail-details',
                            meta: {
                                permissions: ['admin'],
                            },
                            component: () =>
                                import(
                                    '@/js/views/dashboard/Inventory/details/Details.vue'
                                ),
                        },
                        {
                            path: 'inventory-detail/create/:inventory_id',
                            name: 'inventory-details-create',
                            meta: {
                                permissions: ['admin'],
                            },
                            component: () =>
                                import(
                                    '@/js/views/dashboard/Inventory/details/Details.vue'
                                ),
                        },

                        // DASHBOARD - ACCESSORIES STOCK
                        {
                            path: 'accessories-stock/create',
                            name: 'accessories-stock-create',
                            meta: {
                                permissions: ['admin'],
                            },
                            component: () =>
                                import(
                                    '@/js/views/dashboard/accessoriesStock/AccessoriesStockDetails.vue'
                                ),
                        },
                        {
                            path: 'accessories-stock/:id/details',
                            name: 'accessories-stock-details',
                            meta: {
                                permissions: ['admin'],
                            },
                            component: () =>
                                import(
                                    '@/js/views/dashboard/accessoriesStock/AccessoriesStockDetails.vue'
                                ),
                        },
                        // DASHBOARD - BIKES CATALOG
                        {
                            path: 'bikes-catalog/create',
                            name: 'bikes-catalog-create',
                            meta: {
                                permissions: ['admin'],
                            },
                            component: () =>
                                import(
                                    '@/js/views/dashboard/bikesCatalog/BikesCatalogDetails.vue'
                                ),
                        },
                        {
                            path: 'bikes-catalog/:id/details',
                            name: 'bikes-catalog-details',
                            meta: {
                                permissions: ['admin'],
                            },
                            component: () =>
                                import(
                                    '@/js/views/dashboard/bikesCatalog/BikesCatalogDetails.vue'
                                ),
                        },
                        {
                            path: 'bikes-catalog/list',
                            name: 'bikes-catalog-list',
                            meta: {
                                permissions: ['admin'],
                            },
                            component: () =>
                                import(
                                    '@/js/views/dashboard/bikesCatalog/BikesList.vue'
                                ),
                        },
                        {
                            path: 'bikes-catalog/scrapping/list',
                            name: 'bikes-catalog-scrapping-list',
                            meta: {
                                permissions: ['admin'],
                            },
                            component: () =>
                                import(
                                    '@/js/views/dashboard/bikesCatalog/Scrapping/List.vue'
                                ),
                        },
                        {
                            path: 'bikes-catalog/scrapping/cube',
                            name: 'bikes-catalog-scrapping-cube',
                            meta: {
                                permissions: ['admin'],
                            },
                            component: () =>
                                import(
                                    '@/js/views/dashboard/bikesCatalog/Scrapping/Cube.vue'
                                ),
                        },
                        {
                            path: 'bikes-catalog/cafeteria/list',
                            name: 'bikes-catalog-cafeteria-list',
                            meta: {
                                permissions: ['order'],
                            },
                            component: () =>
                                import(
                                    '@/js/views/dashboard/bikesCatalog/CafeteriaList.vue'
                                ),
                        },
                        // DASHBOARD - EXTERNAL BIKES
                        {
                            path: 'external-bikes/list',
                            name: 'external-bikes-list',
                            meta: {
                                permissions: ['admin', 'fleetManager'],
                            },
                            component: () =>
                                import(
                                    '@/js/views/dashboard/externalBikes/BikesList.vue'
                                ),
                        },
                        {
                            path: 'external-bikes/:id/details',
                            name: 'external-bikes-details',
                            meta: {
                                permissions: ['admin', 'fleetManager'],
                            },
                            component: () =>
                                import(
                                    '@/js/views/dashboard/externalBikes/Details.vue'
                                ),
                        },
                        {
                            path: 'external-bikes/create',
                            name: 'external-bikes-create',
                            meta: {
                                permissions: ['admin'],
                            },
                            component: () =>
                                import(
                                    '@/js/views/dashboard/externalBikes/Details.vue'
                                ),
                        },
                        // DASHBOARD - ACCESSORIES CATALOG
                        {
                            path: 'accessories-catalog/create',
                            name: 'accessories-catalog-create',
                            meta: {
                                permissions: ['admin'],
                            },
                            component: () =>
                                import(
                                    '@/js/views/dashboard/accessoriesCatalog/AccessoriesCatalogDetails.vue'
                                ),
                        },
                        {
                            path: 'accessories-catalog/list',
                            name: 'accessories-catalog-list',
                            meta: {
                                permissions: ['admin'],
                            },
                            component: () =>
                                import(
                                    '@/js/views/dashboard/accessoriesCatalog/AccessoriesList.vue'
                                ),
                        },
                        {
                            path: 'accessories-catalog/:id/details',
                            name: 'accessories-catalog-details',
                            meta: {
                                permissions: ['admin'],
                            },
                            component: () =>
                                import(
                                    '@/js/views/dashboard/accessoriesCatalog/AccessoriesCatalogDetails.vue'
                                ),
                        },

                        // DASHBOARD - ENCODAGE
                        {
                            path: 'stock-encode',
                            name: 'stock-encode',
                            meta: {
                                permissions: ['admin'],
                            },
                            component: () =>
                                import(
                                    '@/js/views/dashboard/others/StockEncode.vue'
                                ),
                        },
                        // Bikes list for Insurance
                        {
                            path: 'bikes/list/insurance',
                            name: 'bikes-list-insurance',
                            component: () =>
                                import(
                                    '@/js/views/dashboard/bikesStock/Insurance.vue'
                                ),
                        },

                        // DASHBOARD - ENTRETIENS
                        {
                            path: 'entretiens/:id/details',
                            name: 'entretiens-details',
                            meta: {
                                permissions: ['admin'],
                            },
                            component: () =>
                                import(
                                    '@/js/views/dashboard/entretiens/EntretienDetails.vue'
                                ),
                        },
                        {
                            path: 'entretiens/list',
                            name: 'entretiens-list',
                            meta: {
                                permissions: ['admin'],
                            },
                            component: () =>
                                import(
                                    '@/js/views/dashboard/entretiens/EntretienList.vue'
                                ),
                        },
                        {
                            path: 'shop/schedule',
                            name: 'shop-schedule',
                            meta: {
                                permissions: ['admin'],
                            },
                            component: () =>
                                import(
                                    '@/js/views/dashboard/others/ShopSchedule.vue'
                                ),
                        },
                        {
                            path: 'entretiens/capacity',
                            name: 'entretiens-capacity',
                            meta: {
                                permissions: ['admin'],
                            },
                            component: () =>
                                import(
                                    '@/js/views/dashboard/entretiens/EntretienCapacity.vue'
                                ),
                        },
                        {
                            path: 'entretiens/tour/list',
                            name: 'tour-list',
                            meta: {
                                permissions: ['admin'],
                            },
                            component: () =>
                                import(
                                    '@/js/views/dashboard/entretiens/tours/ToursList.vue'
                                ),
                        },
                        {
                            path: 'entretiens/tour/:id/details',
                            name: 'tour-details',
                            meta: {
                                permissions: ['admin'],
                            },
                            component: () =>
                                import(
                                    '@/js/views/dashboard/entretiens/tours/TourDetails.vue'
                                ),
                        },
                        {
                            path: 'entretiens/list/to-propose-for-tour',
                            name: 'entretiens-to-propose-for-tour',
                            meta: {
                                permissions: ['admin'],
                            },
                            component: () =>
                                import(
                                    '@/js/views/dashboard/entretiens/tours/EntretiensToProposeForTour.vue'
                                ),
                        },
                        {
                            path: 'entretiens/list/to-confirm-for-tour',
                            name: 'entretiens-to-confirm-for-tour',
                            meta: {
                                permissions: ['admin'],
                            },
                            component: () =>
                                import(
                                    '@/js/views/dashboard/entretiens/tours/EntretiensToConfirmForTour.vue'
                                ),
                        },
                        {
                            path: 'entretiens/tours/map',
                            name: 'tours-map',
                            meta: {
                                permissions: ['admin'],
                            },
                            component: () =>
                                import(
                                    '@/js/views/dashboard/entretiens/tours/Map.vue'
                                ),
                        },
                        {
                            path: 'entretiens/create',
                            name: 'create-entretien',
                            meta: {
                                permissions: ['admin'],
                            },
                            component: () =>
                                import(
                                    '@/js/views/dashboard/entretiens/CreateEntretiens.vue'
                                ),
                        },

                        // DASHBOARD - BILLS
                        {
                            path: 'bills/:id/details',
                            name: 'bills-details',
                            meta: {
                                permissions: ['bills'],
                            },
                            component: () =>
                                import(
                                    '@/js/views/dashboard/bills/BillDetails.vue'
                                ),
                        },
                        // DASHBOARD - STATISTICS
                        {
                            path: 'statistics/sales',
                            name: 'statistics-sales',
                            meta: {
                                permissions: ['admin'],
                            },
                            component: () =>
                                import(
                                    '@/js/views/dashboard/statistics/Sales.vue'
                                ),
                        },
                        {
                            path: 'statistics/forecast',
                            name: 'statistics-forecast',
                            meta: {
                                permissions: ['admin'],
                            },
                            component: () =>
                                import(
                                    '@/js/views/dashboard/statistics/Forecast.vue'
                                ),
                        },
                        {
                            path: 'statistics/margin',
                            name: 'statistics-margin',
                            meta: {
                                permissions: ['admin'],
                            },
                            component: () =>
                                import(
                                    '@/js/views/dashboard/statistics/Margin.vue'
                                ),
                        },
                        {
                            path: 'statistics/day-by-day',
                            name: 'statistics-day-by-day',
                            meta: {
                                permissions: ['admin'],
                            },
                            component: () =>
                                import(
                                    '@/js/views/dashboard/statistics/DayByDay.vue'
                                ),
                        },
                        // DASHBOARD - BLOG
                        {
                            path: 'blog/create',
                            name: 'blog-create',
                            meta: {
                                permissions: ['admin'],
                            },
                            component: () =>
                                import(
                                    '@/js/views/dashboard/blog/BlogDetails.vue'
                                ),
                        },
                        {
                            path: 'blog/:slug/details',
                            name: 'blog-details',
                            meta: {
                                permissions: ['admin'],
                            },
                            component: () =>
                                import(
                                    '@/js/views/dashboard/blog/BlogDetails.vue'
                                ),
                        },
                        {
                            path: 'blog/list',
                            name: 'blog-list',
                            meta: {
                                permissions: ['admin'],
                            },
                            component: () =>
                                import(
                                    '@/js/views/dashboard/blog/BlogList.vue'
                                ),
                        },
                        // DASHBOARD - GROUPED ORDERS
                        {
                            path: 'grouped-orders/list',
                            name: 'grouped-orders-list',
                            meta: {
                                permissions: ['admin'],
                            },
                            component: () =>
                                import(
                                    '@/js/views/dashboard/groupedOrders/GroupedOrdersList.vue'
                                ),
                        },
                        {
                            path: 'grouped-orders/create',
                            name: 'grouped-orders-create',
                            meta: {
                                permissions: ['admin'],
                            },
                            component: () =>
                                import(
                                    '@/js/views/dashboard/groupedOrders/GroupedOrdersCreate.vue'
                                ),
                        },
                        {
                            path: 'grouped-orders/:id/details',
                            name: 'grouped-orders-details',
                            meta: {
                                permissions: ['admin'],
                            },
                            component: () =>
                                import(
                                    '@/js/views/dashboard/groupedOrders/GroupedOrdersDetails.vue'
                                ),
                        },
                        {
                            path: 'grouped-orders/:id/bike/:bikeId/details',
                            name: 'grouped-orders-bike-details',
                            meta: {
                                permissions: ['admin'],
                            },
                            component: () =>
                                import(
                                    '@/js/views/dashboard/groupedOrders/bike/GroupedOrdersBikeDetails.vue'
                                ),
                        },
                        {
                            path: 'grouped-orders/:id/add-bike',
                            name: 'grouped-orders-add-bike',
                            meta: {
                                permissions: ['admin'],
                            },
                            component: () =>
                                import(
                                    '@/js/views/dashboard/groupedOrders/bike/GroupedOrdersBikeDetails.vue'
                                ),
                        },
                        {
                            path: 'grouped-orders/:id/accessory/:accessoryId/details',
                            name: 'grouped-orders-accessory-details',
                            meta: {
                                permissions: ['admin'],
                            },
                            component: () =>
                                import(
                                    '@/js/views/dashboard/groupedOrders/accessory/GroupedOrdersAccessoryDetails.vue'
                                ),
                        },
                        {
                            path: 'grouped-orders/:id/add-accessory',
                            name: 'grouped-orders-add-accessory',
                            meta: {
                                permissions: ['admin'],
                            },
                            component: () =>
                                import(
                                    '@/js/views/dashboard/groupedOrders/accessory/GroupedOrdersAccessoryDetails.vue'
                                ),
                        },
                        {
                            path: 'grouped-orders/:id/other-accessory/:otherAccessoryId/details',
                            name: 'grouped-orders-other-accessory-details',
                            meta: {
                                permissions: ['admin'],
                            },
                            component: () =>
                                import(
                                    '@/js/views/dashboard/groupedOrders/otherAccessory/GroupedOrdersOtherAccessoryDetails.vue'
                                ),
                        },
                        {
                            path: 'grouped-orders/:id/add-other-accessory',
                            name: 'grouped-orders-add-other-accessory',
                            meta: {
                                permissions: ['admin'],
                            },
                            component: () =>
                                import(
                                    '@/js/views/dashboard/groupedOrders/otherAccessory/GroupedOrdersOtherAccessoryDetails.vue'
                                ),
                        },
                        {
                            path: 'grouped-orders/:id/boxe/:boxeId/details',
                            name: 'grouped-orders-boxe-details',
                            meta: {
                                permissions: ['admin'],
                            },
                            component: () =>
                                import(
                                    '@/js/views/dashboard/groupedOrders/boxe/GroupedOrdersBoxeDetails.vue'
                                ),
                        },
                        {
                            path: 'grouped-orders/:id/add-boxe',
                            name: 'grouped-orders-add-boxe',
                            meta: {
                                permissions: ['admin'],
                            },
                            component: () =>
                                import(
                                    '@/js/views/dashboard/groupedOrders/boxe/GroupedOrdersBoxeDetails.vue'
                                ),
                        },
                        {
                            path: 'grouped-orders/:id/service/:serviceId/details',
                            name: 'grouped-orders-service-details',
                            meta: {
                                permissions: ['admin'],
                            },
                            component: () =>
                                import(
                                    '@/js/views/dashboard/groupedOrders/service/GroupedOrdersServiceDetails.vue'
                                ),
                        },
                        {
                            path: 'grouped-orders/:id/add-service',
                            name: 'grouped-orders-add-service',
                            meta: {
                                permissions: ['admin'],
                            },
                            component: () =>
                                import(
                                    '@/js/views/dashboard/groupedOrders/service/GroupedOrdersServiceDetails.vue'
                                ),
                        },
                        // Bike orders

                        {
                            path: 'bike-orders/list',
                            name: 'bike-orders-list',
                            meta: {
                                permissions: ['admin'],
                            },
                            component: () =>
                                import(
                                    '@/js/views/dashboard/groupedOrders/GroupedOrdersList.vue'
                                ),
                        },

                        {
                            path: 'checkout',
                            name: 'checkout',
                            meta: {
                                permissions: ['admin'],
                            },
                            component: () =>
                                import(
                                    '@/js/views/dashboard/groupedOrders/checkout/Index.vue'
                                ),
                        },
                        // DASHBOARD - BILLS
                        {
                            path: 'bills/list',
                            name: 'bills-list',
                            meta: {
                                permissions: ['admin'],
                            },
                            component: () =>
                                import(
                                    '@/js/views/dashboard/bills/BillList.vue'
                                ),
                        },
                        {
                            path: 'bills/create',
                            name: 'bills-create',
                            meta: {
                                permissions: ['admin'],
                            },
                            component: () =>
                                import(
                                    '@/js/views/dashboard/bills/BillDetails.vue'
                                ),
                        },

                        {
                            path: 'bills/:id/details',
                            name: 'bills-details',
                            meta: {
                                permissions: ['admin'],
                            },
                            component: () =>
                                import(
                                    '@/js/views/dashboard/bills/BillDetails.vue'
                                ),
                        },
                        {
                            path: 'bills/to-create',
                            name: 'bills-to-create',
                            meta: {
                                permissions: ['admin'],
                            },
                            component: () =>
                                import(
                                    '@/js/views/dashboard/bills/BillsToCreate.vue'
                                ),
                        },

                        {
                            path: 'bills/to-send',
                            name: 'bills-to-send',
                            meta: {
                                permissions: ['admin'],
                            },
                            component: () =>
                                import(
                                    '@/js/views/dashboard/bills/BillToSend.vue'
                                ),
                        },
                        {
                            path: 'bills/tickets-to-bill',
                            name: 'tickets-to-bill',
                            meta: {
                                permissions: ['admin'],
                            },
                            component: () =>
                                import(
                                    '@/js/views/dashboard/bills/TicketsToBill.vue'
                                ),
                        },
                        {
                            path: 'sepa/list',
                            name: 'bills-sepa',
                            meta: {
                                permissions: ['admin'],
                            },
                            component: () =>
                                import(
                                    '@/js/views/dashboard/bills/Domiciliation.vue'
                                ),
                        },
                        {
                            path: 'sepa/create',
                            name: 'bills-sepa-create',
                            meta: {
                                permissions: ['admin'],
                            },

                            component: () =>
                                import(
                                    '@/js/views/dashboard/bills/DomiciliationCreate.vue'
                                ),
                        },
                        {
                            path: 'bills/entretiens-to-bill',
                            name: 'entretiens-to-bill',
                            meta: {
                                permissions: ['admin'],
                            },
                            component: () =>
                                import(
                                    '@/js/views/dashboard/bills/EntretiensToBill.vue'
                                ),
                        },
                        {
                            path: 'bills/cartes-cadeau',
                            name: 'gift-cards',
                            meta: {
                                permissions: ['admin'],
                            },
                            component: () =>
                                import(
                                    '@/js/views/dashboard/bills/GiftCards.vue'
                                ),
                        },
                        {
                            path: 'bills/bills-bikes-link',
                            name: 'bills-bikes-link',
                            component: () =>
                                import(
                                    '@/js/views/dashboard/bills/LinkBillsBikes.vue'
                                ),
                        },
                        {
                            path: 'bills/bills-bikes-link/:billId/update',
                            name: 'link-bill-bike-update',
                            component: () =>
                                import(
                                    '@/js/views/dashboard/bills/LinkBillsBikesUpdate.vue'
                                ),
                        },

                        {
                            path: 'bills/bikes-to-link',
                            name: 'bikes-to-link',
                            component: () =>
                                import(
                                    '@/js/views/dashboard/bills/BikesToLink.vue'
                                ),
                        },

                        {
                            path: 'bills/external-financing-bikes',
                            name: 'external-financing-bikes',
                            component: () =>
                                import(
                                    '@/js/views/dashboard/depreciation/ExternalFinancingBikes.vue'
                                ),
                        },

                        // Amortissements

                        // Vue globale

                        {
                            path: 'accounting/depreciation/overview',
                            name: 'depreciation-overview',
                            meta: {
                                permissions: ['admin'],
                            },
                            component: () =>
                                import(
                                    '@/js/views/dashboard/depreciation/Overview.vue'
                                ),
                        },

                        // DASHBOARD - OFFERS
                        {
                            path: 'offers/list',
                            name: 'offers-list',
                            meta: {
                                permissions: ['admin'],
                            },
                            component: () =>
                                import(
                                    '@/js/views/dashboard/offers/OffersList.vue'
                                ),
                        },
                        {
                            path: 'offers/create',
                            name: 'offers-create',
                            meta: {
                                permissions: ['admin'],
                            },
                            component: () =>
                                import(
                                    '@/js/views/dashboard/offers/OfferDetails.vue'
                                ),
                        },
                        {
                            path: 'offers/:id/details',
                            name: 'offers-details',
                            meta: {
                                permissions: ['admin'],
                            },
                            component: () =>
                                import(
                                    '@/js/views/dashboard/offers/OfferDetails.vue'
                                ),
                        },
                        {
                            path: 'offers/:id/bike/:bikeId/details',
                            name: 'offers-bike-details',
                            meta: {
                                permissions: ['admin'],
                            },
                            component: () =>
                                import(
                                    '@/js/views/dashboard/offers/bike/OffersBikeDetails.vue'
                                ),
                        },
                        {
                            path: 'offers/bike/:id/add-bike',
                            name: 'offers-add-bike',
                            meta: {
                                permissions: ['admin'],
                            },
                            component: () =>
                                import(
                                    '@/js/views/dashboard/offers/bike/OffersBikeDetails.vue'
                                ),
                        },
                        {
                            path: 'offers/:id/accessory/:accessoryId/details',
                            name: 'offers-accessory-details',
                            meta: {
                                permissions: ['admin'],
                            },
                            component: () =>
                                import(
                                    '@/js/views/dashboard/offers/accessory/OffersAccessoryDetails.vue'
                                ),
                        },
                        {
                            path: 'offers/accessory/:id/add-accessory',
                            name: 'offers-add-accessory',
                            meta: {
                                permissions: ['admin'],
                            },
                            component: () =>
                                import(
                                    '@/js/views/dashboard/offers/accessory/OffersAccessoryDetails.vue'
                                ),
                        },
                        {
                            path: 'offers/:id/boxe/:boxeId/details',
                            name: 'offers-boxe-details',
                            meta: {
                                permissions: ['admin'],
                            },
                            component: () =>
                                import(
                                    '@/js/views/dashboard/offers/boxe/OffersBoxeDetails.vue'
                                ),
                        },
                        {
                            path: 'offers/boxe/:id/add-boxe',
                            name: 'offers-add-boxe',
                            meta: {
                                permissions: ['admin'],
                            },
                            component: () =>
                                import(
                                    '@/js/views/dashboard/offers/boxe/OffersBoxeDetails.vue'
                                ),
                        },
                        {
                            path: 'offers/:id/otherAccessory/:otherAccessoryId/details',
                            name: 'offers-other-accessory-details',
                            meta: {
                                permissions: ['admin'],
                            },
                            component: () =>
                                import(
                                    '@/js/views/dashboard/offers/otherAccessory/OffersOtherAccessoryDetails.vue'
                                ),
                        },
                        {
                            path: 'offers/otherAccessory/:id/add-otherAccessory',
                            name: 'offers-add-other-accessory',
                            meta: {
                                permissions: ['admin'],
                            },
                            component: () =>
                                import(
                                    '@/js/views/dashboard/offers/otherAccessory/OffersOtherAccessoryDetails.vue'
                                ),
                        },
                        {
                            path: 'offers/:id/service/:serviceId/details',
                            name: 'offers-service-details',
                            meta: {
                                permissions: ['admin'],
                            },
                            component: () =>
                                import(
                                    '@/js/views/dashboard/offers/service/OffersServiceDetails.vue'
                                ),
                        },
                        {
                            path: 'offers/service/:id/add-service',
                            name: 'offers-add-service',
                            meta: {
                                permissions: ['admin'],
                            },
                            component: () =>
                                import(
                                    '@/js/views/dashboard/offers/service/OffersServiceDetails.vue'
                                ),
                        },
                        // DASHBOARD - OFFERS
                        {
                            path: 'offers/interested',
                            name: 'offers-interested',
                            meta: {
                                permissions: ['admin'],
                            },
                            component: () =>
                                import(
                                    '@/js/views/dashboard/offers/interested/List.vue'
                                ),
                        },
                        {
                            path: 'offers/interested/:id/details',
                            name: 'offers-interested-details',
                            meta: {
                                permissions: ['admin'],
                            },
                            component: () =>
                                import(
                                    '@/js/views/dashboard/offers/interested/Details.vue'
                                ),
                        },
                        {
                            path: 'offers/interested/create',
                            name: 'offers-add-interested',
                            meta: {
                                permissions: ['admin'],
                            },
                            component: () =>
                                import(
                                    '@/js/views/dashboard/offers/interested/Details.vue'
                                ),
                        },

                        // DASHBOARD - CAFETERIA
                        {
                            path: 'reservations/create',
                            name: 'reservations-create',
                            meta: {
                                permissions: ['search', 'bikeMechanic'],
                            },
                            component: () =>
                                import(
                                    '@/js/views/dashboard/reservations/ReservationCreate.vue'
                                ),
                        },
                        {
                            path: 'reservations/:code/confirmation',
                            name: 'reservations-confirmation',
                            meta: {
                                permissions: ['search', 'bikeMechanic'],
                            },
                            component: () =>
                                import(
                                    '@/js/views/dashboard/reservations/ReservationConfirmation.vue'
                                ),
                        },
                        {
                            path: 'reservations/:id/details',
                            name: 'reservations-details',
                            meta: {
                                permissions: [
                                    'search',
                                    'fleetManager',
                                    'admin',
                                ],
                            },
                            component: () =>
                                import(
                                    '@/js/views/dashboard/reservations/reservationDetail/ReservationDetails.vue'
                                ),
                        },

                        // DASHBOARD - OCCASION
                        {
                            path: 'occasion/list',
                            name: 'occasion-list',
                            meta: {
                                permissions: ['admin'],
                            },
                            component: () =>
                                import(
                                    '@/js/views/dashboard/occasion/OccasionList.vue'
                                ),
                        },
                        {
                            path: 'occasion/create',
                            name: 'occasion-create',
                            meta: {
                                permissions: ['admin'],
                            },
                            component: () =>
                                import(
                                    '@/js/views/dashboard/occasion/OccasionDetails.vue'
                                ),
                        },
                        {
                            path: 'occasion/details/:id',
                            name: 'occasion-details',
                            meta: {
                                permissions: ['admin'],
                            },
                            component: () =>
                                import(
                                    '@/js/views/dashboard/occasion/OccasionDetails.vue'
                                ),
                        },

                        {
                            path: 'occasion/rachat-vente',
                            name: 'rachat-vente-occasion',
                            meta: {
                                permissions: ['admin'],
                            },
                            component: () =>
                                import(
                                    '@/js/views/dashboard/occasion/RachatVenteOccasion.vue'
                                ),
                        },

                        // DASHBOARD - ASSISTANCE
                        {
                            path: 'assistance',
                            name: 'assistance',
                            component: () =>
                                import(
                                    '@/js/views/dashboard/reservations/Assistance.vue'
                                ),
                        },
                        // DASHBOARD - FLEET - BILLS
                        {
                            path: 'fleet/bills/list',
                            name: 'fleet-bills-list',
                            component: () =>
                                import(
                                    '@/js/views/dashboard/bills/BillList.vue'
                                ),
                        },
                        // DASHBOARD - FLEET - USERS
                        {
                            path: 'fleet/users/list',
                            name: 'fleet-users-list',
                            meta: {
                                permissions: ['fleetManager'],
                            },
                            component: () =>
                                import(
                                    '@/js/views/dashboard/fleetManager/users/UserList.vue'
                                ),
                        },
                        {
                            path: 'fleet/users/create/',
                            name: 'fleet-users-create',
                            meta: {
                                permissions: ['fleetManager'],
                            },
                            component: () =>
                                import(
                                    '@/js/views/dashboard/fleetManager/users/UserDetails.vue'
                                ),
                        },
                        {
                            path: 'fleet/users/:id/details',
                            name: 'fleet-users-details',
                            meta: {
                                permissions: ['fleetManager'],
                            },
                            component: () =>
                                import(
                                    '@/js/views/dashboard/fleetManager/users/UserDetails.vue'
                                ),
                        },
                        // DASHBOARD - FLEET - RESERVATIONS
                        {
                            path: 'reservations/list',
                            name: 'reservations-list',
                            meta: {
                                permissions: ['fleetManager', 'admin'],
                            },
                            component: () =>
                                import(
                                    '@/js/views/dashboard/reservations/ReservationList.vue'
                                ),
                        },
                        // DASHBOARD - FLEET - ORDERS
                        {
                            path: 'fleet/orders/list',
                            name: 'fleet-orders-list',
                            meta: {
                                permissions: ['fleetManager'],
                            },
                            component: () =>
                                import(
                                    '@/js/views/dashboard/fleetManager/orders/OrderList.vue'
                                ),
                        },
                        {
                            path: 'fleet/order/:id/details',
                            name: 'fleet-order-details',
                            meta: {
                                permissions: ['fleetManager'],
                            },
                            component: () =>
                                import(
                                    '@/js/views/dashboard/fleetManager/orders/OrderDetails.vue'
                                ),
                        },
                        // DASHBOARD - FLEET - OFFERS
                        {
                            path: 'fleet/offer/:id/details',
                            name: 'fleet-offer-details',
                            meta: {
                                permissions: ['fleetManager'],
                            },
                            component: () =>
                                import(
                                    '@/js/views/dashboard/fleetManager/offers/OfferDetails.vue'
                                ),
                        },
                        // DASHBOARD - FLEET - BIKES
                        {
                            path: 'fleet/bikes/list',
                            name: 'fleet-bikes-list',
                            meta: {
                                permissions: ['fleetManager', 'bikeMechanic'],
                            },
                            component: () =>
                                import(
                                    '@/js/views/dashboard/fleetManager/bikes/BikeList.vue'
                                ),
                        },
                        {
                            path: 'fleet/bike/:id/details',
                            name: 'fleet-bike-details',
                            meta: {
                                permissions: ['fleetManager', 'bikeMechanic'],
                            },
                            component: () =>
                                import(
                                    '@/js/views/dashboard/fleetManager/bikes/BikeDetails.vue'
                                ),
                        },
                        // DASHBOARD - FLEET - BOXES
                        {
                            path: 'boxes/list',
                            name: 'boxes-list',
                            meta: {
                                permissions: [
                                    'fleetManager',
                                    'bikeMechanic',
                                    'admin',
                                ],
                            },
                            component: () =>
                                import(
                                    '@/js/views/dashboard/boxes/BoxList.vue'
                                ),
                        },
                        {
                            path: 'boxes/statistics',
                            name: 'boxes-statistics',
                            meta: {
                                permissions: ['admin'],
                            },
                            component: () =>
                                import(
                                    '@/js/views/dashboard/boxes/BoxStatistics.vue'
                                ),
                        },

                        {
                            path: 'boxes/:id/details',
                            name: 'boxes-details',
                            meta: {
                                permissions: [
                                    'fleetManager',
                                    'bikeMechanic',
                                    'admin',
                                ],
                            },
                            component: () =>
                                import(
                                    '@/js/views/dashboard/boxes/BoxDetails.vue'
                                ),
                        },
                        // DASHBOARD - FLEET - CONDITIONS

                        {
                            path: 'fleet/conditions/:id/details',
                            name: 'fleet-conditions-details',
                            meta: {
                                permissions: ['fleetManager'],
                            },
                            component: () =>
                                import(
                                    '@/js/views/dashboard/companies/conditions/ConditionDetails.vue'
                                ),
                        },
                        {
                            path: 'fleet/conditions/:id/partage-list',
                            name: 'fleet-conditions-partage-list',
                            meta: {
                                permissions: ['fleetManager'],
                            },
                            component: () =>
                                import(
                                    '@/js/views/dashboard/companies/conditions/partage/PartageList.vue'
                                ),
                        },

                        {
                            path: 'fleet/conditions/:id/partage-create',
                            name: 'fleet-conditions-partage-create',
                            meta: {
                                permissions: ['fleetManager'],
                            },
                            component: () =>
                                import(
                                    '@/js/views/dashboard/companies/conditions/partage/PartageDetails.vue'
                                ),
                        },
                        {
                            path: 'fleet/conditions/:id/partage-details/:conditionId',
                            name: 'fleet-conditions-partage-update',
                            meta: {
                                permissions: ['fleetManager'],
                            },
                            component: () =>
                                import(
                                    '@/js/views/dashboard/companies/conditions/partage/PartageDetails.vue'
                                ),
                        },
                        // DASHBOARD - FLEET - ENTRETIENS
                        {
                            path: 'entretiens/list',
                            name: 'fleet-entretiens-list',
                            component: () =>
                                import(
                                    '@/js/views/dashboard/fleetManager/entretiens/EntretienList.vue'
                                ),
                        },
                        {
                            path: 'entretiens/list/waiting_answer',
                            name: 'fleet-entretiens-list-waiting_answer',
                            component: () =>
                                import(
                                    '@/js/views/dashboard/fleetManager/entretiens/EntretienList.vue'
                                ),
                        },
                        {
                            path: 'fleet/entretien/:id/details',
                            name: 'fleet-entretien-details',
                            meta: {
                                permissions: ['fleetManager'],
                            },
                            component: () =>
                                import(
                                    '@/js/views/dashboard/fleetManager/entretiens/EntretienDetails.vue'
                                ),
                        },
                        // DASHBOARD - FLEET - CALENDAR

                        {
                            path: 'fleet/calendar/',
                            name: 'fleet-calendar',
                            meta: {
                                permissions: ['fleetManager'],
                            },
                            component: () =>
                                import(
                                    '@/js/views/dashboard/fleetManager/calendar/CalendarList.vue'
                                ),
                        },
                        // DASHBOARD - FLEET - STATS

                        {
                            path: 'fleet/stats/',
                            name: 'fleet-stats',
                            meta: {
                                permissions: ['fleetManager'],
                            },
                            component: () =>
                                import(
                                    '@/js/views/dashboard/fleetManager/stats/Stats.vue'
                                ),
                        },
                        // DASHBOARD - FLEET - QUiZ
                        {
                            path: 'fleet/quiz/',
                            name: 'fleet-quiz',
                            meta: {
                                permissions: ['fleetManager'],
                                access: 'quiz',
                            },
                            component: () =>
                                import(
                                    '@/js/views/dashboard/fleetManager/quiz/QuizDetails.vue'
                                ),
                        },
                        // DASHBOARD - USER - ORDERS
                        {
                            path: 'user/orders/list',
                            name: 'user-orders-list',
                            component: () =>
                                import(
                                    '@/js/views/dashboard/fleetManager/orders/OrderList.vue'
                                ),
                        },
                        {
                            path: 'user/order/:id/details',
                            name: 'user-order-details',
                            component: () =>
                                import(
                                    '@/js/views/dashboard/fleetManager/orders/OrderDetails.vue'
                                ),
                        },
                        // DASHBOARD - USER - OFFERS
                        {
                            path: 'user/offers/list',
                            name: 'user-offers-list',
                            component: () =>
                                import(
                                    '@/js/views/dashboard/fleetManager/offers/OfferList.vue'
                                ),
                        },
                        {
                            path: 'user/offer/:id/details',
                            name: 'user-offer-details',
                            component: () =>
                                import(
                                    '@/js/views/dashboard/fleetManager/offers/OfferDetails.vue'
                                ),
                        },
                        // DASHBOARD - USER - BIKES
                        {
                            path: 'user/bikes/list',
                            name: 'user-bikes-list',
                            component: () =>
                                import(
                                    '@/js/views/dashboard/fleetManager/bikes/BikeList.vue'
                                ),
                        },
                        {
                            path: 'user/bike/:id/details',
                            name: 'user-bike-details',
                            component: () =>
                                import(
                                    '@/js/views/dashboard/fleetManager/bikes/BikeDetails.vue'
                                ),
                        },
                        // DASHBOARD - USER - ENTRETIENS
                        {
                            path: 'user/entretiens/list',
                            name: 'user-entretiens-list',
                            component: () =>
                                import(
                                    '@/js/views/dashboard/fleetManager/entretiens/EntretienList.vue'
                                ),
                        },
                        {
                            path: 'user/entretiens/list/waiting_answer',
                            name: 'user-entretiens-list-waiting_answer',
                            component: () =>
                                import(
                                    '@/js/views/dashboard/fleetManager/entretiens/EntretienList.vue'
                                ),
                        },
                        {
                            path: 'user/entretien/:id/details',
                            name: 'user-entretien-details',
                            component: () =>
                                import(
                                    '@/js/views/dashboard/fleetManager/entretiens/EntretienDetails.vue'
                                ),
                        },
                        // OTHERS
                        {
                            path: 'how-to-order-a-bike',
                            name: 'how-to-order-a-bike',
                            component: () =>
                                import(
                                    '@/js/views/dashboard/others/HowToOrderABike.vue'
                                ),
                        },
                        {
                            path: 'technical-characteristics',
                            name: 'technical-characteristics',
                            component: () =>
                                import(
                                    '@/js/views/dashboard/others/TechnicalCharacteristics.vue'
                                ),
                        },
                    ],
                },
            ],
        },
        {
            path: '/:catchAll(.*)',
            redirect: {
                name: '404',
                params: {
                    lang: 'fr',
                },
            },
        },
    ];
}
